.main{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 90px;
  width: 100%;
  color: #FFFFFF;
  .btn{
    //margin-right: 30px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 150px;
    cursor: pointer;
    height: 100%;
    z-index: 1;
    .active{
      position: absolute;
      bottom: 0px;
      left: 0px;
      display: none;
      width: 100%;
      height: 5px;
      background-color: #CCC;
    }
  }
  .btn:hover{

    .active{
      display: block;
      position: absolute;
      bottom: 0px;
      left: 0px;
      width: 100%;
      height: 5px;
      background-color: #CCC;
    }
  }

  .blackboard{
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100vh;
    width: 100%;
    background-color: rgba(0,0,0,0.5);
    z-index: 0;
    display: none;
  }
}