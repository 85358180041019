@media screen and (min-width: 1563px){
  .bodymain {

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    text-decoration: none;
    .title{
      font-size: 60px;
      font-weight: 600;
      color: #454545;
      margin-left: 50px;
      width: calc(100% - 100px);
    }
    .companies{
      width: 100%;
      max-width: 1920px;
      display: flex;

      flex-direction: column;
      align-items: center;
      //margin-top: 150px;

      .firm{
        display: flex;
        justify-content: space-between;
        width: calc(100% - 100px);
        height: 100%;
        border: 1px solid #CCC;
        margin-bottom: 30px;
        padding: 20px;
        .ontheleft{
            width: 300px;
            height: 490px;
            background-size: 80%;
          background-repeat: no-repeat;
          background-position: center center;
          padding-left: 20px;
        }
        .ontheright{
          max-width: calc(100% - 300px);
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .upper{
            display: flex;
            flex-direction: column;
            .titlename{
              font-size: 30px;
              font-weight: 600;
              max-width: 800px;
            }
            .cover{
              width: 100%;
              max-width: 800px;
              height: 300px;
              background-size: cover ;
              background-position: center center;
              margin: 20px 0;
            }
            .descript{
              max-width: 800px;
            }
          }

        }
        .footer{
          max-width: 100%;
          width: 20%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .urllink{
            color: #454545;
            width: 200px;
            padding: 20px 50px;
            border: 1px solid #CCC;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: 0.3s ease-in-out;
          }
          .urllink:hover{
            background-color: rgba(72,20,160,1);
            border: 1px solid rgba(72,20,160,1);
            transition: 0.3s ease-in-out;
            color: #FFFFFF;
          }
          .contact{
            width: 260px;
            padding: 20px 20px;
            border: 1px solid #CCC;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-top: 30px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 1563px) and (min-width: 900px){
  .bodymain {

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    text-decoration: none;
    .title{
      font-size: 60px;
      font-weight: 600;
      color: #454545;
      margin-left: 50px;
      width: calc(100% - 100px);
    }
    .companies{
      width: 100%;
      max-width: 1920px;
      display: flex;

      flex-direction: column;
      align-items: center;
      //margin-top: 150px;

      .firm{
        display: flex;
        //flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        width: calc(100% - 100px);
        height: 100%;
        border: 1px solid #CCC;
        margin-bottom: 30px;
        padding: 20px;
        .ontheleft{
          width: 300px;
          height: 490px;
          background-size: cover;
          padding-left: 20px;
        }
        .ontheright{
          max-width: calc(100% - 400px);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .upper{
            display: flex;
            flex-direction: column;
            .titlename{
              font-size: 30px;
              font-weight: 600;
              max-width: 800px;
            }
            .cover{
              //display: none;
              width: 100%;
              max-width: 800px;
              height: 300px;
              background-size: cover ;
              background-position: center center;
              margin: 20px 0;
            }
            .descript{
              max-width: 800px;
            }
          }

        }
        .footer{
          max-width: 100%;
          width: 100%;
          height: 100%;
          display: flex;
          //flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
          margin-top: 20px;
          .urllink{

            color: #454545;
            width: 200px;
            padding: 20px 50px;
            border: 1px solid #CCC;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: 0.3s ease-in-out;
          }
          .urllink:hover{
            background-color: rgba(72,20,160,1);
            border: 1px solid rgba(72,20,160,1);
            transition: 0.3s ease-in-out;
            color: #FFFFFF;
          }
          .contact{
            width: 260px;
            padding: 20px 20px;
            border: 1px solid #CCC;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            //margin-top: 30px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 900px) and (min-width: 700px){
  .bodymain {

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    text-decoration: none;
    .title{
      font-size: 60px;
      font-weight: 600;
      color: #454545;
      margin-left: 50px;
      width: calc(100% - 100px);
      margin-top: 100px;
    }
    .companies{
      width: 100%;
      max-width: 1920px;
      display: flex;

      flex-direction: column;
      align-items: center;
      //margin-top: 150px;

      .firm{
        display: flex;
        //flex-direction: column;
        flex-wrap: wrap;
        justify-content: space-between;
        width: calc(100% - 100px);
        height: 100%;
        border: 1px solid #CCC;
        margin-bottom: 30px;
        padding: 20px;
        .ontheleft{
          width: calc(300px / 2);
          height: calc(490px / 2);
          background-size: cover;
          padding-left: 20px;
        }
        .ontheright{
          max-width: calc(100% - 200px);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .upper{
            display: flex;
            flex-direction: column;
            .titlename{
              font-size: 30px;
              font-weight: 600;
              max-width: 800px;
            }
            .cover{
              //display: none;
              width: 100%;
              max-width: 800px;
              height: 300px;
              background-size: cover ;
              background-position: center center;
              margin: 20px 0;
            }
            .descript{
              max-width: 800px;
            }
          }

        }
        .footer{
          max-width: 100%;
          width: 100%;
          height: 100%;
          display: flex;
          //flex-direction: column;
          justify-content: space-between;
          align-items: flex-end;
          margin-top: 20px;
          .urllink{

            color: #454545;
            width: 200px;
            padding: 20px 50px;
            border: 1px solid #CCC;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: 0.3s ease-in-out;
          }
          .urllink:hover{
            background-color: rgba(72,20,160,1);
            border: 1px solid rgba(72,20,160,1);
            transition: 0.3s ease-in-out;
            color: #FFFFFF;
          }
          .contact{
            width: 260px;
            padding: 20px 20px;
            border: 1px solid #CCC;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            //margin-top: 30px;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 700px){
  .bodymain {

    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100vh;
    text-decoration: none;
    .title{
      font-size: 40px;
      font-weight: 600;
      color: #454545;
      margin-left: 15px;
      width: calc(100% - 100px);
      margin-top: 120px;
      margin-bottom: 20px;
    }
    .companies{
      width: 100%;
      max-width: 1920px;
      display: flex;
      flex-direction: column;
      align-items: center;
      //margin-top: 150px;

      .firm{
        display: flex;
        flex-direction: column;
        //flex-wrap: wrap;
        //justify-content: space-between;
        align-items: center;
        width: calc(100% - 50px);
        height: 100%;
        border: 1px solid #CCC;
        margin-bottom: 30px;
        padding: 20px;
        .ontheleft{
          width: calc(300px);
          height: calc(450px);
          background-size: cover;
          padding-left: 20px;
        }
        .ontheright{
          max-width: calc(100%);
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          .upper{
            display: flex;
            flex-direction: column;
            margin-top: 20px;
            .titlename{
              font-size: 30px;
              font-weight: 600;
              max-width: 800px;
            }
            .cover{
              display: none;
              width: 100%;
              max-width: 800px;
              height: 300px;
              background-size: cover ;
              background-position: center center;
              margin: 20px 0;
            }
            .descript{
              max-width: 800px;
            }
          }

        }
        .footer{
          max-width: 100%;
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px;
          .urllink{

            color: #454545;
            width: calc(100% - 100px);
            padding: 20px 50px;
            border: 1px solid #CCC;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: 0.3s ease-in-out;
          }
          .urllink:hover{
            background-color: rgba(72,20,160,1);
            border: 1px solid rgba(72,20,160,1);
            transition: 0.3s ease-in-out;
            color: #FFFFFF;
          }
          .contact{
            width: calc(100% - 40px);
            padding: 20px 20px;
            border: 1px solid #CCC;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            margin-top: 20px;
          }
        }
      }
    }
  }
}
