::-webkit-scrollbar {
  width: 8px;  /* Ширина всей полосы прокрутки */
}

::-webkit-scrollbar-track {
  background-color: #000;  /* Цвет полосы прокрутки */
}

::-webkit-scrollbar-thumb {
  background-color: #8F4899;  /* Цвет ползунка */
  border-radius: 4px;  /* Закругленные края ползунка */
}


*{
  margin: 0px;
  padding: 0px;
  text-decoration: none;
  scrollbar-width: thin;  /* Устанавливает тонкую полосу прокрутки */
  scrollbar-color: #8F4899 #000;  /* Устанавливает цвет ползунка и полосы прокрутки */
  /*outline: 1px solid red;*/

}

div{
  /*width: 100%;*/
}

@font-face {
  font-family: 'Mont';
  src: url('./fonts/Mont-Regular.woff') format('woff'),
  url('./fonts/Mont-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Mont';
  src: url('./fonts/Mont-Bold.woff') format('woff'),
  url('./fonts/Mont-Bold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: 'Mont';
  src: url('./fonts/Mont-Black.woff') format('woff'),
  url('./fonts/Mont-Black.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'ALS';
  src: url('./fonts/ALS_Tongyin_Regular.otf') format('otf'),
  url('./fonts/ALS_Tongyin_Black.otf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'ALS';
  src: url('./fonts/ALS_Tongyin_Black.otf') format('otf'),
  url('./fonts/ALS_Tongyin_Black.otf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

* {
  font-family: 'Mont', sans-serif;
  font-weight: 400;
  font-optical-sizing: auto;
}

.bodymain{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  text-decoration: none;
}