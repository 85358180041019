@media screen and (min-width: 1500px){
  .main{
    width: 100%;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;

    .container{
      width: 100%;
      max-width: 1920px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .blocks{
        width: calc(100% - 60px);
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 30px;
        color: #454545;
        .one:hover{
          transition: 0.2s ease-in-out;
          .left{
            transition: 0.2s ease-in-out;

            .next{
              background-size: 88%;
              width: 80px;
              transition: 0.2s ease-in-out;
            }

          }
          .right{
            transition: 0.2s ease-in-out;
            .text{
              font-size: 46px;
              transition: 0.2s ease-in-out;
            }
          }

        }
        .one{
          width: calc(30% - 30px);
          min-width: 300px;
          height: 600px;
          border: 1px solid #CCC;
          margin-top: 24px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          transition: 0.2s ease-in-out;
          .left{
            width: calc(100% - 90px);
            height: 285px;
            padding: 0 45px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            transition: 0.2s ease-in-out;
            .next{
              width: 101px;
              height: 144px;
              background-image: url("../images/next.svg");
              background-size: 98%;
              background-repeat: no-repeat;
              transition: 0.2s ease-in-out;
              background-position: center center;
              margin: 0 30px 0 0 ;
            }
          }
          .right{
            width: calc(100% - 90px);
            height: 285px;
            padding: 0 45px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            transition: 0.2s ease-in-out;
            .text{
              display: flex;
              justify-content: flex-end;
              font-size: 40px;
              transition: 0.2s ease-in-out;
            }
          }


        }
        .two{
          width: calc(70%);
          min-width: 300px;
          height: 600px;
          margin-top: 24px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          .block:hover{
            transition: 0.2s ease-in-out;
            .left{
              transition: 0.2s ease-in-out;
              width: calc(100px);

              .next{
                background-size: 88%;
                //background-position: 100% 100%;
                transition: 0.2s ease-in-out;
              }
            }

            .right{
              transition: 0.2s ease-in-out;
              .text{
                font-size: 46px;
                transition: 0.2s ease-in-out;
              }
            }
          }
          .block{
            width: 100%;
            height: 285px;
            border: 1px solid #CCC;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            transition: 0.2s ease-in-out;
            .left{
              width: calc(120px);
              height: 285px;
              padding: 0 45px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              transition: 0.2s ease-in-out;
              .next{
                width: 101px;
                height: 144px;
                background-image: url("../images/next.svg");
                background-size: 98%;
                background-repeat: no-repeat;
                transition: 0.2s ease-in-out;
                background-position: center center;
                margin: 0 30px 0 0 ;
              }
            }
            .right{
              width: calc(100% - 30px);
              height: 285px;
              padding: 0 45px;
              display: flex;
              justify-content: flex-end;
              align-items: center;
              transition: 0.2s ease-in-out;
              .text{
                display: flex;
                justify-content: flex-end;
                font-size: 40px;
                transition: 0.2s ease-in-out;
              }
            }


          }
        }

        .three:hover{
          transition: 0.2s ease-in-out;
          .left{
            transition: 0.2s ease-in-out;
            width: calc(100px);
            .next{
              background-size: 88%;
              //background-position: 100% 100%;
              transition: 0.2s ease-in-out;
            }
          }

          .right{
            transition: 0.2s ease-in-out;
            .text{
              font-size: 46px;
              transition: 0.2s ease-in-out;
            }
          }

        }
        .three{
          width: calc(70%);
          min-width: 300px;
          height: 285px;
          border: 1px solid #CCC;
          margin-top: 24px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          transition: 0.2s ease-in-out;
          .left{
            width: calc(120px);
            height: 285px;
            padding: 0 45px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            transition: 0.2s ease-in-out;
            .next{
              width: 101px;
              height: 144px;
              background-image: url("../images/next.svg");
              background-size: 98%;
              background-repeat: no-repeat;
              background-position: center center;
              transition: 0.2s ease-in-out;
              margin: 0 30px 0 0 ;
            }
          }
          .right{
            width: calc(100% - 30px);
            height: 285px;
            padding: 0 45px;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            transition: 0.2s ease-in-out;
            .text{
              display: flex;
              justify-content: flex-end;
              font-size: 40px;
              transition: 0.2s ease-in-out;
            }
          }


        }
        .four:hover{
          transition: 0.2s ease-in-out;
          .left{
            transition: 0.2s ease-in-out;
            width: calc(100px);
            .next{
              background-size: 88%;
              //background-position: 100% 100%;
              transition: 0.2s ease-in-out;
            }
          }

          .right{
            transition: 0.2s ease-in-out;
            .text{
              font-size: 46px;
              transition: 0.2s ease-in-out;
            }
          }

        }
        .four{
          width: calc(30% - 30px);
          min-width: 300px;
          height: 285px;
          border: 1px solid #CCC;
          margin-top: 24px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          transition: 0.2s ease-in-out;
          cursor: pointer;
          .left{
            width: calc(120px);
            height: 285px;
            padding: 0 45px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            transition: 0.2s ease-in-out;
            .next{
              width: 101px;
              height: 144px;
              background-image: url("../images/next.svg");
              background-size: 98%;
              background-repeat: no-repeat;
              transition: 0.2s ease-in-out;
              background-position: center center;
              margin: 0 30px 0 0 ;
            }
          }
          .right{
            width: calc(100% - 30px);
            height: 285px;
            padding: 0 40px 0 0;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            transition: 0.2s ease-in-out;
            .text{
              display: flex;
              justify-content: center;
              align-items: center;
              font-size: 40px;
              transition: 0.2s ease-in-out;
            }
          }


        }
      }
    }
  }
}
@media screen and (max-width: 1500px){
  .main{
    width: 100%;
    min-height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;

    .container{
      width: 100%;
      max-width: 1920px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .blocks{
        width: calc(100% - 60px);
        height: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 30px;
        color: #454545;
        //.one:hover{
        //  transition: 0.2s ease-in-out;
        //  .left{
        //    transition: 0.2s ease-in-out;
        //
        //    .next{
        //      background-size: 88%;
        //      width: 80px;
        //      transition: 0.2s ease-in-out;
        //    }
        //
        //  }
        //  .right{
        //    transition: 0.2s ease-in-out;
        //    .text{
        //      font-size: 46px;
        //      transition: 0.2s ease-in-out;
        //    }
        //  }
        //
        //}
        .one{
          width: calc(100% - 0px);
          min-width: 300px;
          //height: 600px;
          border: 1px solid #CCC;
          margin-top: 24px;
          display: flex;
          //flex-direction: column;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          transition: 0.2s ease-in-out;
          .left{
            width: 101px;
            //height: 185px;
            padding: 0 20px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            transition: 0.2s ease-in-out;
            .next{
              width: 35px;
              height: 52px;
              background-image: url("../images/next.svg");
              background-size: 98%;
              background-repeat: no-repeat;
              transition: 0.2s ease-in-out;
              background-position: center center;
              margin: 0 0px 0 0 ;
            }
          }
          .right{
            width: calc(100% - 131px);
            height: 285px;
            padding: 0 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.2s ease-in-out;
            .text{
              display: flex;
              justify-content: flex-end;
              font-size: 20pt;
              transition: 0.2s ease-in-out;
            }
          }


        }
        .two{
          width: calc(100%);
          min-width: 300px;
          height: 600px;
          margin-top: 24px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          //.block:hover{
          //  transition: 0.2s ease-in-out;
          //  .left{
          //    transition: 0.2s ease-in-out;
          //    width: calc(100px);
          //
          //    .next{
          //      background-size: 88%;
          //      //background-position: 100% 100%;
          //      transition: 0.2s ease-in-out;
          //    }
          //  }
          //
          //  .right{
          //    transition: 0.2s ease-in-out;
          //    .text{
          //      font-size: 46px;
          //      transition: 0.2s ease-in-out;
          //    }
          //  }
          //}
          .block{
            width: 100%;
            height: 285px;
            border: 1px solid #CCC;
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            transition: 0.2s ease-in-out;
            .left{
              width: 101px;
              //height: 185px;
              padding: 0 20px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              transition: 0.2s ease-in-out;
              .next{
                width: 35px;
                height: 52px;
                background-image: url("../images/next.svg");
                background-size: 98%;
                background-repeat: no-repeat;
                transition: 0.2s ease-in-out;
                background-position: center center;
                margin: 0 0px 0 0 ;
              }
            }
            .right{
              width: calc(100% - 131px);
              height: 285px;
              padding: 0 15px;
              display: flex;
              justify-content: center;
              align-items: center;
              transition: 0.2s ease-in-out;
              .text{
                display: flex;
                justify-content: flex-end;
                font-size: 20pt;
                transition: 0.2s ease-in-out;
              }
            }



          }
        }
        //.three:hover{
        //  transition: 0.2s ease-in-out;
        //  .left{
        //    transition: 0.2s ease-in-out;
        //    width: calc(100px);
        //
        //    .next{
        //      background-size: 88%;
        //      //background-position: 100% 100%;
        //      transition: 0.2s ease-in-out;
        //    }
        //
        //  }
        //  .right{
        //    transition: 0.2s ease-in-out;
        //    .text{
        //      font-size: 46px;
        //      transition: 0.2s ease-in-out;
        //    }
        //  }
        //
        //}
        .three{
          width: calc(100%);
          min-width: 300px;
          height: 285px;
          border: 1px solid #CCC;
          margin-top: 24px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          cursor: pointer;
          transition: 0.2s ease-in-out;
          .left{
            width: 101px;
            //height: 185px;
            padding: 0 20px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            transition: 0.2s ease-in-out;
            .next{
              width: 35px;
              height: 52px;
              background-image: url("../images/next.svg");
              background-size: 98%;
              background-repeat: no-repeat;
              transition: 0.2s ease-in-out;
              background-position: center center;
              margin: 0 0px 0 0 ;
            }
          }
          .right{
            width: calc(100% - 131px);
            height: 285px;
            padding: 0 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.2s ease-in-out;
            .text{
              display: flex;
              justify-content: flex-end;
              font-size: 20pt;
              transition: 0.2s ease-in-out;
            }
          }


        }
        //.four:hover{
        //  transition: 0.2s ease-in-out;
        //  .left{
        //    transition: 0.2s ease-in-out;
        //    width: calc(100px);
        //    .next{
        //      background-size: 88%;
        //      //background-position: 100% 100%;
        //      transition: 0.2s ease-in-out;
        //    }
        //  }
        //
        //  .right{
        //    transition: 0.2s ease-in-out;
        //    .text{
        //      font-size: 46px;
        //      transition: 0.2s ease-in-out;
        //    }
        //  }
        //
        //}
        .four{
          width: calc(100%);
          min-width: 300px;
          height: 285px;
          border: 1px solid #CCC;
          margin-top: 24px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          transition: 0.2s ease-in-out;
          cursor: pointer;
          .left{
            width: 101px;
            //height: 185px;
            padding: 0 20px;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            transition: 0.2s ease-in-out;
            .next{
              width: 35px;
              height: 52px;
              background-image: url("../images/next.svg");
              background-size: 98%;
              background-repeat: no-repeat;
              transition: 0.2s ease-in-out;
              background-position: center center;
              margin: 0 0px 0 0 ;
            }
          }
          .right{
            width: calc(100% - 131px);
            height: 285px;
            padding: 0 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: 0.2s ease-in-out;
            .text{
              display: flex;
              justify-content: flex-end;
              font-size: 20pt;
              transition: 0.2s ease-in-out;
            }
          }


        }
      }
    }
  }
}
