@media screen and (min-width: 900px){
  .news_block{
    width: calc(100% / 3 - 30px);
    height: 100%;
    min-height: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFFFF;
    padding: 30px 0;
    position: relative;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    border: 1px solid #CCC;
    margin-bottom: 40px;
    .content{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      .img{
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        width: calc(100% - 60px);
        height: calc(1080px / 3 - 30px);
        img{
          width: 100%;
          background-size: cover;
          background-position: center center;
          transition: 0.2s ease-in-out;
        }
      }

      .date{
        width: calc(100% - 60px);
        font-size: 20px;
      }
      .name{
        width: calc(100% - 60px);
        font-size: 24px;
        margin-top: 20px;
        font-weight: 600;
        transition: 0.2s ease-in-out;
      }
      .active{
        position: absolute;
        bottom: -49px;
        width: 100%;
        height: 50px;
        display: flex;
        background-color: #8F4899;
        opacity: 0;
        transition: 0.2s ease-in-out;
      }
    }
    .btnblock{
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom: 0px;
      z-index: 1;
      width: 100%;
      height: 100%;
      transition: 0.2s ease-in-out;
      .btn{
        width: calc(100% - 60px);
        height: calc(100% - 60px);
        padding: 30px;
        display: none;
        align-items: flex-start;
        text-transform: uppercase;
        background-color: rgba(72,20,160,0.8);
        box-shadow: 0px 3px 10px rgba(0,0,0,0.2);
        flex-direction: column;
        justify-content: space-around;
        transition: 0.2s ease-in-out;
        .next{
          width: 101px;
          height: 144px;
          background-image: url("../images/nextwhite.svg");
          background-size: 98%;
          background-repeat: no-repeat;
          transition: 0.2s ease-in-out;
          background-position: center center;
          margin: 0 0 0 30px ;
        }
        .text{
          width: calc(100% - 30px);
          margin-right: 30px;
          display: flex;
          justify-content: flex-end;
          color: #FFFFFF;
          font-size: 40px;
          transition: 0.2s ease-in-out;
        }
      }
    }
  }

  .news_block:hover{
    transition: 0.2s ease-in-out;
    .btnblock{
      transition: 0.2s ease-in-out;
      .btn{
        display: flex;
        transition: 0.2s ease-in-out;
      }
    }

  }
}
@media screen and (max-width: 900px){
  .news_block{
    width: calc(100% + 0px);
    height: 100%;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFFFF;
    padding: 30px 0;
    position: relative;
    cursor: pointer;
    transition: 0.2s ease-in-out;
    //border: 1px solid #CCC;
    margin-bottom: 0px;
    margin-left: 0px;
    .content{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      width: calc(100%);
      img{
        width: calc(100%);
        //height: calc(1080px / 3 - 30px);
        background-size: cover;
        background-position: center center;
        transition: 0.2s ease-in-out;
      }
      .date{
        width: calc(100% - 60px);
        font-size: 20px;
        color: #454545;
      }
      .name{
        width: calc(100% - 60px);
        font-size: 20px;
        margin-top: 10px;
        font-weight: 400;
        transition: 0.2s ease-in-out;
      }
      .active{
        position: absolute;
        bottom: -49px;
        width: 100%;
        height: 50px;
        display: flex;
        background-color: #8F4899;
        opacity: 0;
        transition: 0.2s ease-in-out;
      }
    }
    .btnblock{
      display: flex;
      justify-content: space-between;
      position: absolute;
      bottom: 0px;
      z-index: 1;
      width: 100%;
      height: 100%;
      transition: 0.2s ease-in-out;
      .btn{
        width: calc(100% - 60px);
        height: calc(100% - 60px);
        padding: 30px;
        display: none;
        align-items: flex-start;
        text-transform: uppercase;
        background-color: rgba(72,20,160,0.8);
        box-shadow: 0px 3px 10px rgba(0,0,0,0.2);
        flex-direction: column;
        justify-content: space-around;
        transition: 0.2s ease-in-out;
        .next{
          width: 101px;
          height: 144px;
          background-image: url("../images/nextwhite.svg");
          background-size: 98%;
          background-repeat: no-repeat;
          transition: 0.2s ease-in-out;
          background-position: center center;
          margin: 0 0 0 30px ;
        }
        .text{
          width: calc(100% - 30px);
          margin-right: 30px;
          display: flex;
          justify-content: flex-end;
          color: #FFFFFF;
          font-size: 40px;
          transition: 0.2s ease-in-out;
        }
      }
    }
  }

  .news_block:hover{
    transition: 0.2s ease-in-out;
    .btnblock{
      transition: 0.2s ease-in-out;
      .btn{
        display: flex;
        transition: 0.2s ease-in-out;
      }
    }

  }
}

