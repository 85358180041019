.bodymain{
  display: flex;
  flex-direction: column;
  align-items: center;
  //justify-content: flex-start;
  width: 100%;
  min-height: 100vh;


  .blockvideo{
    max-width: 1920px;
    width: 100%;
    position: relative;
    margin-top: -100vh;
    video{
      width: 100%;
      //height: 100%;
      max-width: 1920px;
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: -1;
    }
  }

}