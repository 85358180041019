@media screen and (min-width: 900px){
  .main{
    width: 100%;
    height: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .container{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      background-color: #FFF;
      margin-bottom: 50px;
      //background-color: #000;
      .btnsnext{
        display: none;
      }
      .title{
        width: calc(100% - 60px);
        padding: 0 30px;
        max-width: 1920px;
        //font-family: 'ALS', sans-serif;
        font-weight: 800;
        font-size: 90px;
        text-transform: uppercase;
        color: #410099;
        margin-bottom: 50px;
        margin-top: 100px;
      }
      .newsmobile{
        display: none;
      }
      .news{
        width: calc(100% - 60px);
        max-width: 1920px;
        height: 100%;
        min-height: 500px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        .nextbtn:hover{
          border: 1px solid #410099;
          transition: 0.2s;
        }
        .nextbtn{
          width: 98px;
          height: 100%;
          border: 1px solid #CCC;
          min-height: 560px;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: 0.2s;
          cursor: pointer;
          .next{
            width: 55px;
            height: 70px;
            background-image: url("../images/next.svg");
            background-size: 98%;
            background-repeat: no-repeat;
            transition: 0.2s ease-in-out;
            background-position: center center;
            margin: 0 0px 0 0;
            border: none;
          }
          .turnnext{
            width: 55px;
            height: 70px;
            background-image: url("../images/next.svg");
            background-size: 98%;
            background-repeat: no-repeat;
            transition: 0.2s ease-in-out;
            background-position: center center;
            margin: 0 0px 0 0;
            border: none;
            transform: rotate(180deg);
          }
        }

        .news_block{
          width: calc(100% / 3 - 30px - (200px / 3));
          height: 100%;
          min-height: 500px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          background-color: #FFFFFF;
          padding: 30px 0;
          position: relative;
          cursor: pointer;
          transition: 0.2s ease-in-out;
          margin-bottom: -1px;
          border: 1px solid #CCC;
          .img{
            width: calc(100% - 60px);
            height: calc(1080px / 3 - 30px);
            background-size: cover;
            background-position: center center;
            transition: 0.2s ease-in-out;
          }
          .date{
            width: calc(100% - 60px);
            font-size: 14px;
          }
          .name{
            width: calc(100% - 60px);
            font-size: 20px;
            margin-top: 20px;
            transition: 0.2s ease-in-out;
          }
          .active{
            position: absolute;
            bottom: -49px;
            width: calc(100% + 2px);
            height: 50px;
            display: flex;
            background-color: #410099;
            opacity: 0;
            transition: 0.2s ease-in-out;
          }
        }
        .news_block:hover{
          transition: 1s ease-in-out;
          .img{
            width: calc(100% - 50px);
            height: calc(1080px / 3 - 20px);
            //background-size: 120%;
            transition: 0.2s ease-in-out;
          }
          .active{
            opacity: 1;
            transition: 0.2s ease-in-out;
          }
          .name{
            margin-top: 10px;
            transition: 0.2s ease-in-out;
          }
        }

      }
      .comas{
        display: flex;
        width: 100%;
        max-width: 600px;
        margin-top: 30px;
        margin-bottom: 0px;
        .coma{
          display: flex;
          justify-content: center;
          align-items: center;
          width: calc(100% / 4 - 20px);
          height: 30px;
          margin: 0 10px;
          cursor: pointer;
          .slash{
            width: 100%;
            height: 4px;
            background-color: #8F4899;
            opacity: 0.4;
          }
        }
      }
      .more{
        width: 100%;
        max-width: 1920px;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 30px;
        .openmore{
          display: flex;
          justify-content: flex-end;
          align-items: center;
          height: 100%;
          max-width: 450px;
          cursor: pointer;
          transition: 0.2s ease-in-out;
          .next{
            width: 70px;
            height: 104px;
            background-image: url("../images/next.svg");
            background-size: 98%;
            background-repeat: no-repeat;
            transition: 0.2s ease-in-out;
            background-position: center center;
            margin: 0 30px 0 0;
          }
          .title{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-family: 'Mont', sans-serif;
            font-weight: 400;
            color: #454545;
            font-size: 30pt;
            max-width: 400px;
            height: 100%;
            margin: 0;
          }
        }
        .openmore:hover{
          transition: 0.2s ease-in-out;
          .next{
            background-size: 88%;
            transition: 0.2s ease-in-out;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 900px){
  .main{
    width: 100%;
    height: 100%;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .container{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      background-color: #050024;
      margin-bottom: 50px;
      //background-color: #000;
      .title{
        width: calc(100% - 60px);
        padding: 0 30px;
        max-width: 1920px;
        font-family: 'ALS', sans-serif;
        font-weight: 800;
        font-size: 60px;
        text-transform: uppercase;
        color: #FFF;
        margin-bottom: 10px;
        margin-top: 50px;
        text-align: center;
      }

      .news{
        display: none;

      }
      .newsmobile{
        width: calc(100% - 0px);
        //max-width: 1920px;
        height: 100%;
        min-height: 500px;
        display: flex;
        //flex-direction: column;
        //justify-content: space-between;
        align-items: flex-start;
        overflow: hidden;
        transition: transform 0.5s ease;
        .news_block{
          width: calc(100% / 1);
          min-width: 100%;
          box-sizing: border-box;
          height: 100%;
          min-height: 500px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-start;
          background-color: #FFFFFF;
          padding: 30px 0px;
          position: relative;
          cursor: pointer;
          transition: 0.2s ease-in-out;
          margin-bottom: -1px;
          .img{
            width: calc(100% - 60px);
            height: calc(1080px / 3 - 30px);
            background-size: cover;
            background-position: center center;
            transition: 0.2s ease-in-out;
          }
          .date{
            width: calc(100% - 60px);
            font-size: 20px;
          }
          .name{
            width: calc(100% - 60px);
            font-size: 28px;
            margin-top: 20px;
            transition: 0.2s ease-in-out;
          }
          .active{
            position: absolute;
            bottom: -49px;
            width: 100%;
            height: 50px;
            display: flex;
            background-color: #8F4899;
            opacity: 0;
            transition: 0.2s ease-in-out;
          }
        }
        .news_block:hover{
          transition: 1s ease-in-out;
          .img{
            width: calc(100% - 50px);
            height: calc(1080px / 3 - 20px);
            //background-size: 120%;
            transition: 0.2s ease-in-out;
          }
          .active{
            opacity: 1;
            transition: 0.2s ease-in-out;
          }
          .name{
            margin-top: 10px;
            transition: 0.2s ease-in-out;
          }
        }

      }
      .comas{
        display: none;
        width: 100%;
        max-width: 600px;
        margin-top: 30px;
        margin-bottom: 0px;
        .coma{
          display: flex;
          justify-content: center;
          align-items: center;
          width: calc(100% / 4 - 20px);
          height: 30px;
          margin: 0 10px;
          cursor: pointer;
          .slash{
            width: 100%;
            height: 4px;
            background-color: #8F4899;
            opacity: 0.4;
          }
        }
      }
      .more{
        width: 100%;
        max-width: 1920px;
        height: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 0px;
        .openmore{
          display: flex;
          justify-content: flex-end;
          align-items: center;
          height: 100%;
          width: calc(100% - 40px);
          cursor: pointer;
          transition: 0.2s ease-in-out;
          border: 1px solid #050024;
          padding: 20px;
          .next{
            width: 70px;
            height: 104px;
            background-image: url("../images/next.svg");
            background-size: 98%;
            background-repeat: no-repeat;
            transition: 0.2s ease-in-out;
            background-position: center center;
            margin: 0 30px 0 0;
          }
          .title{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            font-family: 'Mont', sans-serif;
            font-weight: 400;
            color: #454545;
            font-size: 14pt;
            max-width: 100%;
            height: 100%;
            margin: 0;
          }
        }
        .openmore:hover{
          transition: 0.2s ease-in-out;
          .next{
            background-size: 88%;
            transition: 0.2s ease-in-out;
          }
        }
      }
      .btnsnext{
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: -49px;
        div{
          border: 1px solid #CCC;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          .next{
            width: 55px;
            height: 70px;
            background-image: url("../images/next.svg");
            background-size: 98%;
            background-repeat: no-repeat;
            transition: 0.2s ease-in-out;
            background-position: center center;
            margin: 0 0px 0 0;
            border: none;
          }
          .turnnext{
            width: 55px;
            height: 70px;
            background-image: url("../images/next.svg");
            background-size: 98%;
            background-repeat: no-repeat;
            transition: 0.2s ease-in-out;
            background-position: center center;
            margin: 0 0px 0 0;
            border: none;
            transform: rotate(180deg);
          }
        }
        .left{
          width: calc(50% - 2px);
        }
        .right{
          width: calc(50% - 1px);
          margin-left: -1px;
        }
      }

    }

  }
}
