.main{
  width: 100%;
  max-width: 1920px;
}

.container{
  width: 100%;
  max-width: 1920px;
  height: 100%;
}

.back{
  width: 100%;
  height: 100vh;
  border-bottom: 2px solid #F1F1F1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .upper{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 90px;
    .logo{
      height: 100%;
      width: 340px;
      background-color: #F1F1F1;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 100px;
      z-index: 1;
      img{
        width: 79%;
      }
    }
    .nav{
      display: flex;
      align-items: center;
      max-width: calc(100% - 340px - 100px);
    }
  }
  .text{
    width: calc(100% - 50px);
    color: #FFFFFF;
    font-size: 100px;
    margin: 0 0 50px 50px;

  }
}