.main{
  width: 100%;
  height: 100%;
  min-height: 400px;
  background-color: #504596;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  .container{
    width: 100%;
    max-width: 1920px;
    height: 100%;
    min-height: 500px;
    max-height: calc(1080px - 500px);
    padding: 50px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: relative;
    .title{
      width: calc(100% - 50px);
      font-size: 50px;
      font-weight: 600;
      text-transform: uppercase;
      position: absolute;
      top: 50px;
      left: 150px;
      color: #FFF;
    }
    .sphere{
      width: 250px;
      height: 250px;
      border-radius: 151px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0,0,0,0);
      border: 1px solid #F1F1F1;
      cursor: pointer;
      transition: 0.2s;
      img{
        width: 70%;
        transition: 0.2s;
      }
      .text{
        display: none;
      }
    }
    .sphere:hover{
      //background-color: #F1F1F1;
      transition: 0.2s;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      img{
        width: 80%;
        transition: 0.2s;
        position: relative;
      }
      .text{
        position: absolute;
        bottom: -90px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 80%;
        height: content-box;
        color: #FFF;
        font-size: 30px;
        font-weight: 500;
        text-align: center;
      }
    }
  }
}