@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
.main{
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.6s ease, transform 0.6s ease;
  animation: fadeInUp 0.6s forwards;
  width: 90%;
  max-width: 500px;
  height: 90%;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 100001;
  color: #454545;
  .forminput{
    border: none;
    border-bottom: 3px solid #CCC;
    width: 90%;
    max-width: 400px;
    height: 50px;
    text-align: center;
    font-size: 22px;
    outline: none;
    transition: 0.2s;
    margin-top: 15px;
    color: #454545;
  }
  .forminput:active{
    border-bottom: 3px solid #6E037B;
    transition: 0.2s;
  }
  .forminput:focus{
    border-bottom: 3px solid #6E037B;
    transition: 0.2s;
  }
  .title{
    width: 90%;
    max-width: 400px;
    font-size: 22px;
    margin-top: 20px;
    color: #454545;
  }
  .formtext{
    width: calc(90% + 4px);
    max-width: 400px;
    height: 90%;
    max-height: calc(90% - (50px + 50px + 50px + 20px + 20px + 20px + 20px + 50px + 50px + 30px));
    resize: none;  /* Отключает изменение размера пользователем */
    box-sizing: border-box; /* Включает бордер и паддинг в размер элемента */
    border-top: 1px solid #CCCCCC;
    border-left: 1px solid #CCCCCC;
    border-right: 1px solid #CCCCCC;
    border-bottom: 3px solid #CCCCCC;
    color: #454545;
    outline: none;
    padding: 10px;
    font-size: 18px;
    margin-top: 15px;
    transition: 0.2s;
  }
  .formtext:focus{
    border-color: #410099;
    transition: 0.2s;
  }
  .btnpost{
    width: 90%;
    max-width: 400px;
    height: 60px;
    text-align: center;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    color: #454545;
    border: 1px solid #454545;
    cursor: pointer;
    transition: 0.2s;
  }
  .btnpost:hover{
    background-color: #410099;
    color: #FFF;
    border: 1px solid #454545;
    transition: 0.2s;
  }
}