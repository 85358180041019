.main{
  width: 100%;
  //min-height: 900px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 100vh;
  .container{
    width: 100%;
    max-width: 1920px;
    height: 100%;
    min-height: 500px;
    max-height: calc(1080px - 500px);
    padding: 150px 0;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .open{
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      height: 70px;
      width: 200px;
      margin-left: 50px;
      font-size: 30px;
      color: #504596;
      cursor: pointer;
      text-decoration: none;
      .iopen{
        width: 50px;
        height: 110px;
        background-image: url("../icons/open.svg");
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: 100%;
        margin-right: 30px;
      }
    }
    .text{
      width: 60%;
      height: calc(100%);
      min-height: 500px;
      max-height: calc(1080px - 500px);
      font-size: 45px;
      margin-right: 50px;
      color: #504596;
    }
  }
}