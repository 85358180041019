@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@media screen and (min-width: 1919px){
  .main{
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.6s ease, transform 0.6s ease;
    animation: fadeInUp 0.6s forwards;
    width: 90%;
    max-width: 1920px;
    height: 90%;
    background-color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100001;
    .leftpart{
      width: calc(50% - 25px - 30px);
      height: calc(100% - 60px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .logo{}
      .title{
        font-size: 20pt;
        text-align: center;
        width: 90%;
      }
      .site{
        padding: 20px 60px;
        border: 1px solid #CCC;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        color: #454545;
      }
      .site:hover{
        background-color: rgba(72,20,160,1);
        border: 1px solid rgba(72,20,160,1);
        transition: 0.3s ease-in-out;
        color: #FFFFFF;
      }
    }

    .rightpart{
      width: 50%;
      height: 100%;
      background: linear-gradient(135deg, #410099 10%, #8F4899 90%);
      display: flex;
      justify-content: center;
      align-items: center;

      .container{
        width: 100%;
        height: 100%;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        .up{
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-top: 50px;
          .image{
            width: calc(100%);
            //max-width: calc((1920px / 100 * 90 / 2 - 25px) / 3 * 2);
            //height: calc(((1920px / 100 * 90 / 2 - 25px) / 3 * 2) / 1920 * 1080 );
            height: calc(1080px / 3);
            background-size: cover;
            background-position: center center;
          }
          .desc{
            color: #FFFFFF;
            margin-top: 30px;
            //text-align: center;
          }
        }

        .contacts{
          //width: calc(100% - 0px);
          color: #454545;
          padding: 15px;
          font-size: 16pt;
          background-color: #FFFFFF;
          margin-bottom: 30px;
          .phone{}
          .email{}
          .adress{}
        }

      }

    }
  }
}
@media screen and (min-width: 1500px) and (max-width: 1919px){
  .main{
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.6s ease, transform 0.6s ease;
    animation: fadeInUp 0.6s forwards;
    width: 90%;
    max-width: 1920px;
    height: 90%;
    background-color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100001;
    .leftpart{
      width: calc(30% - 25px - 0px);
      height: calc(100% - 60px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .logo{}
      .title{
        font-size: 20pt;
        text-align: center;
        width: 90%;
      }
      .site{
        padding: 20px 60px;
        border: 1px solid #CCC;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        color: #454545;
      }
      .site:hover{
        background-color: rgba(72,20,160,1);
        border: 1px solid rgba(72,20,160,1);
        transition: 0.3s ease-in-out;
        color: #FFFFFF;
      }
    }

    .rightpart{
      width: 70%;
      height: 100%;
      background: linear-gradient(135deg, #410099 10%, #8F4899 90%);
      display: flex;
      justify-content: center;
      align-items: center;

      .container{
        width: 100%;
        height: 100%;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        .up{
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-top: 50px;
          .image{
            width: calc(100%);
            //max-width: calc((1920px / 100 * 90 / 2 - 25px) / 3 * 2);
            //height: calc(((1920px / 100 * 90 / 2 - 25px) / 3 * 2) / 1920 * 1080 );
            height: calc(1080px / 3);
            background-size: cover;
            background-position: center center;
          }
          .desc{
            color: #FFFFFF;
            margin-top: 30px;
            //text-align: center;
          }
        }

        .contacts{
          //width: calc(100% - 0px);
          color: #454545;
          padding: 15px;
          font-size: 16pt;
          background-color: #FFFFFF;
          margin-bottom: 30px;
          .phone{}
          .email{}
          .adress{}
        }

      }

    }
  }
}
@media screen and (min-width: 1200px) and (max-width: 1500px){
  .main{
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.6s ease, transform 0.6s ease;
    animation: fadeInUp 0.6s forwards;
    width: 90%;
    max-width: 1920px;
    height: 90%;
    background-color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100001;
    .leftpart{
      width: calc(30% - 25px - 0px);
      height: calc(100% - 60px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .logo{}
      .title{
        font-size: 20pt;
        text-align: center;
        width: 90%;
      }
      .site{
        padding: 20px 60px;
        border: 1px solid #CCC;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        color: #454545;
      }
      .site:hover{
        background-color: rgba(72,20,160,1);
        border: 1px solid rgba(72,20,160,1);
        transition: 0.3s ease-in-out;
        color: #FFFFFF;
      }
    }

    .rightpart{
      width: 70%;
      height: 100%;
      background: linear-gradient(135deg, #410099 10%, #8F4899 90%);
      display: flex;
      justify-content: center;
      align-items: center;

      .container{
        width: 100%;
        height: 100%;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        .up{
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-top: 50px;
          .image{
            width: calc(100%);
            //max-width: calc((1920px / 100 * 90 / 2 - 25px) / 3 * 2);
            //height: calc(((1920px / 100 * 90 / 2 - 25px) / 3 * 2) / 1920 * 1080 );
            height: calc(1080px / 3);
            background-size: cover;
            background-position: center center;
          }
          .desc{
            color: #FFFFFF;
            margin-top: 30px;
            //text-align: center;
          }
        }

        .contacts{
          //width: calc(100% - 0px);
          color: #454545;
          padding: 15px;
          font-size: 16pt;
          background-color: #FFFFFF;
          margin-bottom: 30px;
          .phone{}
          .email{}
          .adress{}
        }

      }

    }
  }
}
@media screen and (min-width: 400px) and (max-width: 1200px){
  .main{
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.6s ease, transform 0.6s ease;
    animation: fadeInUp 0.6s forwards;
    width: 90%;
    max-width: 1920px;
    height: 90%;
    background-color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100001;
    .leftpart{
      width: calc(100% - 0px - 0px);
      height: calc(100% - 60px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .logo{}
      .title{
        font-size: 20pt;
        text-align: center;
        width: 90%;
      }
      .site{
        padding: 20px 60px;
        border: 1px solid #CCC;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        color: #454545;
      }
      .site:hover{
        background-color: rgba(72,20,160,1);
        border: 1px solid rgba(72,20,160,1);
        transition: 0.3s ease-in-out;
        color: #FFFFFF;
      }
    }

    .rightpart{
      width: 70%;
      height: 100%;
      background: linear-gradient(135deg, #410099 10%, #8F4899 90%);
      display: none;
      justify-content: center;
      align-items: center;

      .container{
        width: 100%;
        height: 100%;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        .up{
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-top: 50px;
          .image{
            width: calc(100%);
            //max-width: calc((1920px / 100 * 90 / 2 - 25px) / 3 * 2);
            //height: calc(((1920px / 100 * 90 / 2 - 25px) / 3 * 2) / 1920 * 1080 );
            height: calc(1080px / 3);
            background-size: cover;
            background-position: center center;
          }
          .desc{
            color: #FFFFFF;
            margin-top: 30px;
            //text-align: center;
          }
        }

        .contacts{
          //width: calc(100% - 0px);
          color: #454545;
          padding: 15px;
          font-size: 16pt;
          background-color: #FFFFFF;
          margin-bottom: 30px;
          .phone{}
          .email{}
          .adress{}
        }

      }

    }
  }
}
@media screen and (max-width: 400px){
  .main{
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.6s ease, transform 0.6s ease;
    animation: fadeInUp 0.6s forwards;
    width: 90%;
    max-width: 1920px;
    height: 90%;
    background-color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 100001;
    .leftpart{
      width: calc(100% - 0px - 0px);
      height: calc(100% - 60px);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .logo{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        img{
          width: 50%;
        }
      }
      .title{
        font-size: 16pt;
        text-align: center;
        width: 90%;
      }
      .site{
        padding: 20px 60px;
        border: 1px solid #CCC;
        cursor: pointer;
        transition: 0.3s ease-in-out;
        color: #454545;
      }
      .site:hover{
        background-color: rgba(72,20,160,1);
        border: 1px solid rgba(72,20,160,1);
        transition: 0.3s ease-in-out;
        color: #FFFFFF;
      }
    }

    .rightpart{
      width: 70%;
      height: 100%;
      background: linear-gradient(135deg, #410099 10%, #8F4899 90%);
      display: none;
      justify-content: center;
      align-items: center;

      .container{
        width: 100%;
        height: 100%;
        padding: 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-end;
        .up{
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          margin-top: 50px;
          .image{
            width: calc(100%);
            //max-width: calc((1920px / 100 * 90 / 2 - 25px) / 3 * 2);
            //height: calc(((1920px / 100 * 90 / 2 - 25px) / 3 * 2) / 1920 * 1080 );
            height: calc(1080px / 3);
            background-size: cover;
            background-position: center center;
          }
          .desc{
            color: #FFFFFF;
            margin-top: 30px;
            //text-align: center;
          }
        }

        .contacts{
          //width: calc(100% - 0px);
          color: #454545;
          padding: 15px;
          font-size: 16pt;
          background-color: #FFFFFF;
          margin-bottom: 30px;
          .phone{}
          .email{}
          .adress{}
        }

      }

    }
  }
}